const getCurrencySymbol = (currencyCode: string): string => {
  switch (currencyCode) {
    case 'USD': return '$';
    case 'EUR': return '€';
    default: return currencyCode;
  }
};

export const formatCurrency = (valueInCents: number, currencyCode: string, locale: string): string => {
  const formattedNumber = (valueInCents).toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${formattedNumber} ${getCurrencySymbol(currencyCode)}`;
};

export default formatCurrency;
