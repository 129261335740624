
import Vue, { PropType } from 'vue';
import { BContainer, BCol, BRow } from 'bootstrap-vue';
import AtomAvatar from '@/components/atoms/AtomAvatar.vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import AtomCircularIcon, { CircularIconVariant, CircularIconSize } from '@/components/atoms/AtomCircularIcon.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

import { RideUserModel, RideVehicleModel } from '@/models/ride/RideModel';
import RolesEnum from '@/enums/rides/RolesEnum';
import { formatCurrency } from '@/common/CurrencyFormatter';

import { fetchImage } from '@/api/user/userApi';

export enum CardUserVariant {
  BASIC = 'tg-basic',
  EXPANDED = 'tg-expanded',
  EXPANDED_PICKUP = 'tg-expanded--pickup',
  EXPANDED_DROPOFF = 'tg-expanded--dropoff',
}

export default Vue.extend({
  name: 'MoleculeCardUser',
  components: {
    AtomAvatar,
    AtomBadge,
    AtomCircularIcon,
    AtomSvgIcon,
    BContainer,
    BCol,
    BRow,
  },
  props: {
    variant: {
      type: String as PropType<CardUserVariant>,
      validator: (val: CardUserVariant) => Object.values(CardUserVariant).includes(val),
      default: CardUserVariant.BASIC,
    },
    canExpand: {
      type: Boolean,
      default: false,
    },
    canShrink: {
      type: Boolean,
      default: false,
    },
    occupants: {
      type: Array as PropType<RideUserModel[]>,
      default: () => [] as RideUserModel[],
    },
    car: {
      type: Object as PropType<RideVehicleModel>,
      default: () => ({} as RideVehicleModel),
    },
    isPlaceholder: {
      type: Boolean,
      default: false,
    },
    userIsDriver: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    driver: {} as RideUserModel,
    driverDecodedImage: '',
    users: [] as RideUserModel[],
    vehicle: {},
    RolesEnum,
    BadgeVariant,
    CardUserVariant,
    CircularIconVariant,
    CircularIconSize,
    formatCurrency,
  }),
  computed: {
    isExpandedVariant(): boolean {
      return (
        this.variant === CardUserVariant.EXPANDED ||
        this.variant === CardUserVariant.EXPANDED_PICKUP ||
        this.variant === CardUserVariant.EXPANDED_DROPOFF
      );
    },
    isSingleOccupant(): boolean {
      return this.users.length === 1;
    },
    getOccupants(): RideUserModel[] {
      const { users } = this;
      if (users.length > 5) {
        return users.slice(0, 4);
      }
      return users;
    },
    isCar(): boolean {
      return Object.keys(this.car).length > 0;
    },
  },
  methods: {
    occupantImage(url: string) {
      return fetchImage(url);
    },
    displayRole(role: RolesEnum): string {
      switch (role) {
        case RolesEnum.DRIVER:
          return this.$t('rideIntent.role.driver').toString();
        case RolesEnum.PASSENGER:
          return this.$t('rideIntent.role.passenger').toString();
        case RolesEnum.DRIVER_AND_PASSENGER:
          return this.$t('rideIntent.role.driverOrPassenger').toString();
        default:
          return '';
      }
    },
    async decodeUserImage(user: RideUserModel): Promise<RideUserModel> {
      return { ...user, decodedImage: await fetchImage(user.imageURL) };
    },
    onClick(occupant) {
      this.$emit('onClick', occupant.userId);
    },
    getDriver() {
      const user = this.occupants.find((driver) => driver.role === RolesEnum.DRIVER);
      if (user) {
        this.driver = user;
        this.getDecodedImage();
      }
    },
    async getDecodedImage() {
      await fetchImage(this.driver.imageURL).then((image) => {
        this.driverDecodedImage = image;
      });
    },
    carIcon(car) {
      if (car.decodedImage === '') {
        this.vehicle = { ...this.vehicle, decodedImage: 'icons/icon-car-front-1.svg' };
      }
    },
  },
  watch: {
    async car(newVehicle) {
      this.vehicle = newVehicle.vehicleId
        ? { ...newVehicle, decodedImage: await fetchImage(newVehicle.imageURL) }
        : {};
      this.carIcon(this.vehicle);
    },
    async occupants(newUsers) {
      const { decodeUserImage } = this;
      this.users = await Promise.all(newUsers.map(decodeUserImage));
    },
  },
  async mounted() {
    if (!this.userIsDriver) {
      this.getDriver();
    }
    const { occupants, decodeUserImage } = this;

    this.vehicle = this.car.vehicleId
      ? { ...this.car, decodedImage: await fetchImage(this.car.imageURL) }
      : {};
    this.carIcon(this.vehicle);
    this.users = await Promise.all(occupants.map(decodeUserImage));
  },
});
