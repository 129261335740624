
import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { fetchImage } from '@/api/user/userApi';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomCard from '@/components/atoms/AtomCard.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import MoleculeDateSection from '@/components/molecules/date/MoleculeDateSection.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import OrganismProfileExtra from '@/components/organisms/profile/OrganismProfileExtra.vue';
import OrganismForeignProfileHeader from '@/components/organisms/profile/OrganismForeignProfileHeader.vue';
import OrganismMatchedRide from '@/components/organisms/home/OrganismMatchedRide.vue';
import TripEnum from '@/enums/rides/RolesEnum';
import { RideUserModel } from '@/models/ride/RideModel';
import RideSummaryModel from '@/models/ride/RideSummaryModel';
import { sortMomentAsc, sortMomentDesc } from '@/services/utilities/sort';
import { getUserRating } from '@/api/ratings/ratingsApi';

enum RatingErrorCodes {
  NOT_ENOUGH_RATINGS = 'NOT_ENOUGH_RATINGS',
}

export default Vue.extend({
  name: 'TemplateForeignProfile',
  data: () => ({
    RatingErrorCodes,
    otherUser: {} as RideUserModel,
    decodedCompanyLogo: '',
    matchedRides: [] as RideSummaryModel[],
    blacklistEntryId: '',
    rating: 0,
    ratingAnnotation: '',
    ratingErrorCode: '' as RatingErrorCodes,
  }),
  components: {
    AtomText,
    AtomCard,
    AtomSvgIcon,
    MoleculeDateSection,
    OrganismPageSubHeader,
    OrganismProfileExtra,
    OrganismForeignProfileHeader,
    OrganismMatchedRide,
  },
  computed: {
    ...mapGetters('user', [
      'getOtherUsers',
      'getBlockedUsers',
      'getUserId',
    ]),
    ...mapGetters('rides', ['getRides']),
    ...mapGetters('rides', {
      rides: 'getRidesSummary',
    }),
    tripDates(): moment.Moment[] {
      const now = moment();
      return this.matchedRides.reduce((datesList, { origin: { dateTime } }) => {
        if (dateTime.isBefore(now) && !datesList.some((date) => moment(dateTime).isSame(date, 'day'))) {
          datesList.push(moment(dateTime));
        }
        return datesList;
      }, [] as moment.Moment[])
        .sort(sortMomentDesc);
    },
  },
  methods: {
    tripsPerDay(date: moment.Moment): RideSummaryModel[] {
      return this.matchedRides
        .filter((arrival) => moment(arrival.origin.dateTime).isSame(date, 'day'))
        .sort(({ origin: { dateTime: a } }, { origin: { dateTime: b } }) => sortMomentAsc(a, b));
    },
    setOtherUser() {
      this.getOtherUsers.forEach(async (user) => {
        if (user.userId === this.otherUser.userId) {
          this.otherUser = user;
          await fetchImage(this.otherUser.imageURL).then((image) => {
            this.otherUser.decodedImage = image;
          });
          await fetchImage(user.companyLogo2URL).then((image) => {
            this.decodedCompanyLogo = image;
          });
        }
      });
    },
    setBlockedUserId() {
      this.getBlockedUsers.forEach((user) => {
        if (user.firstName === this.otherUser.firstName && user.lastName === this.otherUser.lastName) {
          this.blacklistEntryId = user.id;
        } else this.blacklistEntryId = '';
      });
    },
    displayRole(role: TripEnum): string {
      if (role === TripEnum.DRIVER) return this.$t('rideIntent.role.driver').toString();
      if (role === TripEnum.PASSENGER) return this.$t('rideIntent.role.passenger').toString();
      if (role === TripEnum.DRIVER_AND_PASSENGER) return this.$t('rideIntent.role.driverOrPassenger').toString();
      return '';
    },
    ridesUser() {
      this.rides.forEach((ride) => {
        ride.occupants.forEach((user) => {
          if (this.otherUser.userId === user.userId) {
            this.matchedRides.push(ride);
          }
        });
      });
    },
    blockUser(id) {
      this.blacklistEntryId = id;
    },
    unblockUser() {
      this.blacklistEntryId = '';
    },
    getRatingSubheadingKey(rating: number, annotation: string): string {
      if (rating < 3) return `ratings.subHeading${rating}`;
      return `ratings.subHeading${rating}_${annotation}`;
    },
    getRatingEmojiIcon(rating: number) {
      switch (rating) {
        case 1:
          return 'icons/icon-emoji-sad.svg';
        case 2:
          return 'icons/icon-emoji-neutral.svg';
        case 3:
          return 'icons/icon-emoji-happy.svg';
        default:
          return '';
      }
    },
  },
  async mounted() {
    this.otherUser.userId = this.$route.params.id;
    this.setOtherUser();
    this.ridesUser();
    window.scroll(0, 0);
    this.setBlockedUserId();

    const res = await getUserRating(this.otherUser.userId);
    this.rating = res.data?.rating || 0;
    this.ratingAnnotation = res.data?.mostRatedAnnotation || 'consideration';
    this.ratingErrorCode = res.message as RatingErrorCodes || '';
  },
});
