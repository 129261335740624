
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import AtomAvatar from '@/components/atoms/AtomAvatar.vue';
import AtomCardWaypoint from '@/components/atoms/AtomCardWaypoint.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import AtomStackedCardList from '@/components/atoms/AtomStackedCards/AtomStackedCardList.vue';
import AtomStackedCardListItem from '@/components/atoms/AtomStackedCards/AtomStackedCardListItem.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import { toast } from '@/components/atoms/AtomToast';
import MoleculeCard, { CardVariant } from '@/components/molecules/cards/MoleculeCard.vue';
import MoleculeCardUser from '@/components/molecules/cards/MoleculeCardUser.vue';
import OrganismUsersRatingModal from '@/components/organisms/ratings/OrganismUsersRatingModal.vue';
import EventTypeEnum from '@/enums/rides/EventsTypeEnum';
import RideSummaryModel from '@/models/ride/RideSummaryModel';
import RouteModel from '@twogo/geolocation/src/lib/models/routing/RouteModel';
import RideDetailsType from '@/enums/rides/RideDetailsTypeEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import { getStaticMapRouteUrl } from '@twogo/here-maps/src/lib/HereMapsService';
import { RideUserModel } from '@/models/ride/RideModel';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';

import { sortString } from '@/services/utilities/sort';

import { skipUserRating, rateUser, suggestAnnotations } from '@/api/ratings/ratingsApi';
import { showParkingInformation, getParkingIcon } from './ride-details/OrganismDetailsService';

export default Vue.extend({
  name: 'OrganismMatchedRide',
  components: {
    AtomAvatar,
    AtomCardWaypoint,
    AtomSvgIcon,
    AtomBadge,
    AtomStackedCardList,
    AtomStackedCardListItem,
    AtomText,
    MoleculeCard,
    MoleculeCardUser,
    OrganismUsersRatingModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    ride: {
      type: Object as PropType<RideSummaryModel>,
      required: true,
    },
    unratedUserIds: {
      type: Array as PropType<string[]>,
      default() {
        return [];
      },
    },
    isPartOfSeries: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    BadgeVariant,
    RolesEnum,
    routes: [] as RouteModel[],
  }),
  methods: {
    ...mapActions('rides', ['removeRatedUser', 'fetchUnratedRides']),
    sortString,
    getNotRatedUserAvatarProps(user: RideUserModel): { imgSrc: string; name: string; company: string|null} {
      return {
        imgSrc: user.decodedImage,
        name: `${user.firstName} ${user.lastName}`,
        company: user.companyName,
      };
    },
    redirectToDetails(id: string): void {
      this.$router.push({
        name: 'RideDetails',
        params: { id, type: RideDetailsType.MATCHED_RIDE },
      });
    },
    navigateToUser(id: string): void {
      if (id === this.getUserId) {
        this.$router.push({ name: 'Profile' });
      } else {
        this.$router.push({
          name: 'ProfileUser',
          params: { id },
        });
      }
    },
    staticMapWithRoute(height = 246): string {
      const queryParams = {
        wayPoints: [this.ride.origin.location, this.ride.destination.location],
        imageSize: { w: 190, h: height },
        lineWeight: 4,
        lineColor: '009eaa',
      };

      return getStaticMapRouteUrl(queryParams);
    },
    async rateUser({ entity: { userId }, rating, annotations }): Promise<void> {
      await rateUser(
        {
          userId,
          matchId: this.ride.matchId,
          rating,
          annotations,
        },
        this.getUserId,
      );

      this.fetchUnratedRides(this.getUserId);
    },
    async skipUserRating({ entity: { userId } }): Promise<void> {
      await skipUserRating(
        {
          userId,
          matchId: this.ride.matchId,
        },
        this.getUserId,
      );

      this.removeRatedUser({ userId, matchId: this.ride.matchId });
    },
    async suggest(suggestedAnnotations: string[]): Promise<void> {
      await suggestAnnotations(suggestedAnnotations);

      toast(this.$bvToast, this.$t('ratings.modal_rate.suggestion_success').toString());
    },
  },
  computed: {
    ...mapGetters('user', ['getUserId', 'getOtherUsers', 'features']),
    ...mapGetters('rides', {
      unRatedRides: 'getGetUnratedRidesById',
      hasUnratedRides: 'hasUnratedRides',
      getIntentById: 'getIntentById',
    }),
    rideIntent(): RideIntentModel {
      return this.getIntentById(this.ride.intentId);
    },
    showParkingDetails(): boolean {
      return showParkingInformation(
        this.ride.role,
        this.features,
        !!this.rideIntent.destination.providesParkingPlace,
      );
    },
    parkingIcon(): string {
      return getParkingIcon(this.rideIntent, moment());
    },
    isPastEvent(): boolean {
      const { eventType } = this.ride;
      return eventType === EventTypeEnum.PAST_MATCH || eventType === EventTypeEnum.PAST_RIDE_INTENT;
    },
    getVariant(): string {
      return this.isPastEvent ? CardVariant.SECONDARY : CardVariant.PRIMARY;
    },
    unratedRideUsers(): RideUserModel[] {
      const { getUnratedUserIds } = this;
      return (this.getOtherUsers as RideUserModel[])
        .filter((u) => getUnratedUserIds.includes(u.userId));
    },
    getUnratedUserIds(): string[] {
      return this.unRatedRides(this.ride.matchId)
        .map((r) => r.userId)
        .sort(sortString);
    },
  },
});
