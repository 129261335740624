
import Vue, { PropType } from 'vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export enum CircularIconVariant {
  PRIMARY = 'circular-icon--primary',
  SECONDARY = 'circular-icon--secondary',
}

export enum CircularIconSize {
  DEFAULT = 'DEFAULT',
  BIG = 'BIG',
  FULL_WIDTH = 'FULL_WIDTH',
}

export default Vue.extend({
  name: 'AtomCircularIcon',
  components: { AtomSvgIcon },
  props: {
    src: {
      type: String,
      required: true,
    },
    variant: {
      type: String as PropType<CircularIconVariant>,
      validator: (val: CircularIconVariant) => Object.values(CircularIconVariant).includes(val),
      default: CircularIconVariant.PRIMARY,
    },
    size: {
      type: String as PropType<CircularIconSize>,
      validator: (val: CircularIconSize) => Object.values(CircularIconSize).includes(val),
      default: CircularIconSize.DEFAULT,
    },
  },
  computed: {
    getClasses() {
      return {
        'circular-icon': true,
        [this.variant]: true,
      };
    },
    getIconSize() {
      if (this.size === CircularIconSize.BIG) {
        return '58';
      }
      return '24';
    },
  },
});
