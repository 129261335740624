
import Vue, { PropType } from 'vue';
import { BBadge } from 'bootstrap-vue';

export enum BadgeVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',

  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export default Vue.extend({
  name: 'AtomBadge',
  components: {
    BBadge,
  },
  props: {
    variant: {
      type: String as PropType<BadgeVariant>,
      validator: (val: BadgeVariant) => Object.values(BadgeVariant).includes(val),
      default: BadgeVariant.PRIMARY,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
});
