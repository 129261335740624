
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { block, follow, unblock, unfollow } from '@/api/community/communityApi';
import AtomAvatar, { AvatarSize } from '@/components/atoms/AtomAvatar.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import MoleculeCard from '@/components/molecules/cards/MoleculeCard.vue';
import MoleculeDropdown from '@/components/molecules/dropdown/MoleculeDropdown.vue';
import { RideUserModel } from '@/models/ride/RideModel';

export default Vue.extend({
  components: {
    AtomAvatar,
    AtomSvgIcon,
    AtomButton,
    AtomBadge,
    MoleculeCard,
    MoleculeDropdown,
  },
  name: 'OrganismForeignProfileHeader',
  props: {
    userId: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    points: {
      type: Number,
      default: 0,
    },
    co2: {
      type: Number,
      default: 0,
    },
    distance: {
      type: Number,
      default: 0,
    },
    phone: {
      type: String,
      required: true,
    },
    isPhoneVerified: {
      type: Boolean,
      required: false,
    },
    email: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      required: false,
    },
    user: {
      type: Object as PropType<RideUserModel>,
      required: false,
    },
    blacklistEntryId: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters('user', {
      getUserId: 'getUserId',
      followedUsers: 'following',
      getBlockedUsers: 'getBlockedUsers',
    }),
    isFollowed(): boolean {
      return this.followedUsers.some((u) => u.userId === this.userId);
    },
  },
  methods: {
    ...mapActions('user', [
      'fetch',
      'setFollowingMembers',
      'fetchBlockedUsers',
      'deleteBlockedUser',
      'addBlockedUser',
    ]),
    async followButtonClick() {
      if (!this.userId) return;
      this.isLoading = true;
      if (!this.isFollowed) {
        const { status, data } = await follow(this.getUserId, [this.userId]);
        if (status === 201) {
          this.setFollowingMembers(data);
          toast(this.$bvToast, this.$t('profile.messages.followUser', { name: `${this.firstName} ${this.lastName}` }).toString(), ToastVariants.SUCCESS);
        } else {
          toast(this.$bvToast, this.$t('profile.messages.error').toString(), ToastVariants.DANGER);
        }
      } else {
        const { status, data } = await unfollow(this.getUserId, this.userId);
        if (status === 201) {
          this.setFollowingMembers(data);
          toast(this.$bvToast, this.$t('profile.messages.unfollowUser', { name: `${this.firstName} ${this.lastName}` }).toString(), ToastVariants.SUCCESS);
        } else {
          toast(this.$bvToast, this.$t('profile.messages.error').toString(), ToastVariants.DANGER);
        }
      }
      this.isLoading = false;
    },
    async blockButtonClick() {
      if (!this.userId) return;

      const { status, data } = await block(this.getUserId, this.user.firstName, this.user.lastName);
      if (status === 201) {
        await this.addBlockedUser(data);
        toast(this.$bvToast, this.$t('otherProfile.blockMessage').toString(), ToastVariants.SUCCESS);
        if (data) {
          this.blockedID = data.id;
          this.$emit('blockUser', data.id);
        }
        if (this.isFollowed) {
          this.followButtonClick();
        }
      }
    },
    async unblockButtonClick() {
      if (!this.userId) return;

      const { status } = await unblock(this.blockedID);
      if (status === 200) {
        await this.deleteBlockedUser(this.blockedID);
        toast(this.$bvToast, this.$t('otherProfile.unblockMessage', { name: `${this.firstName} ${this.lastName}` }).toString(), ToastVariants.SUCCESS);
        this.$emit('unblockUser');
        this.blockedID = '';
      }
    },
    async isUserBlocked() {
      const blockedUsers = this.getBlockedUsers;
      await blockedUsers.forEach((user) => {
        if (user.firstName === this.firstName && user.lastName === this.lastName) {
          this.blockedID = user.id;
        }
      });
    },
  },
  data: () => ({
    AvatarSize,
    BadgeVariant,
    ButtonVariant,
    blockedID: '',
    isLoading: false,
  }),
  watch: {
    blacklistEntryId(id) {
      this.blockedID = id;
    },
  },
  async mounted() {
    setTimeout(this.isUserBlocked, 10);
  },
});
