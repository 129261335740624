
import Vue from 'vue';
import MoleculeCard from '@/components/molecules/cards/MoleculeCard.vue';

const c = Vue.extend({
  name: 'OrganismProfileExtra',
  components: { MoleculeCard },
});

export default c;
